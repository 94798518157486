import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się", title: 'Zaloguj się' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/hub',
    name: 'Hub',
    meta: { pageTitle: "Jawgarden", title: 'Hub' },
    component: () => import(/* webpackChunkName: "Hub" */ '../views/Hub.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/ManageUsers.vue'),
        meta: { pageTitle: "Zarządzaj użytkownikami - Jawgarden", title: 'Zarządzaj użytkownikami systemu' }
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "ShowUserDetails" */ '../views/ShowUserDetails.vue'),
        meta: { pageTitle: "Szczegóły konta - Jawgarden", title: 'Szczegóły konta' }
      },
      {
        path: 'clients',
        component: () => import(/* webpackChunkName: "ManageClients" */ '../views/ManageClients.vue'),
        meta: { pageTitle: "Zarządzaj klientami - Jawgarden", title: 'Zarządzaj klientami systemu' }
      },
      {
        path: 'client',
        component: () => import(/* webpackChunkName: "ShowClientDetails" */ '../views/ShowClientDetails.vue'),
        meta: { pageTitle: "Szczegóły konta - Jawgarden", title: 'Szczegóły klienta' }
      },
      {
        path: 'offers',
        component: () => import(/* webpackChunkName: "ManageOffers" */ '../views/ManageOffers.vue'),
        meta: { pageTitle: "Zarządzaj produktami - Jawgarden", title: 'Zarządzaj produktami' }
      },
      {
        path: 'panel',
        component: () => import(/* webpackChunkName: "ManageEmployees" */ '../views/ManageEmployees.vue'),
        meta: { pageTitle: "Zarządzaj panelem - Jawgarden", title: 'Zarządzaj panelem' }
      },
      {
        path: 'summaries',
        component: () => import(/* webpackChunkName: "ManageEmployees" */ '../views/ManageSummaries.vue'),
        meta: { pageTitle: "Zarządzaj zestawieniami - Jawgarden", title: 'Zarządzaj zestawieniami' }
      },
      {
        path: 'settlement',
        component: () => import(/* webpackChunkName: "ManageEmployees" */ '../views/ManageSummariesAdmin.vue'),
        meta: { pageTitle: "Rozliczenie zestawień - Jawgarden", title: 'Rozlicz zestawienia' }
      },
      {
        path: 'settle',
        component: () => import(/* webpackChunkName: "ShowSummaryDetails" */ '../views/ShowSummaryDetailsAdmin.vue'),
        meta: { pageTitle: "Szczegóły zestawienia - Jawgarden", title: 'Szczegóły zestawienia' }
      },
      {
        path: 'summary',
        component: () => import(/* webpackChunkName: "ShowSummaryDetails" */ '../views/ShowSummaryDetails.vue'),
        meta: { pageTitle: "Szczegóły zestawienia - Jawgarden", title: 'Szczegóły zestawienia' }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

