import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyC5JdZicwfgLzlvShTswF8KlTayjmjY8Nc",
  authDomain: "jawgarden-4e611.firebaseapp.com",
  projectId: "jawgarden-4e611",
  storageBucket: "jawgarden-4e611.appspot.com",
  messagingSenderId: "963322701219",
  appId: "1:963322701219:web:c6395243b82c1def37e616",
  measurementId: "G-MH4612XNB7"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
  //const db = firebase.firestore()
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();
  const app = firebase.app()
  const analytics = getAnalytics(app);

export { firebase, auth, db, storage, app, analytics };