<template>
  <div id="app" class="min-h-screen w-screen relative">
    <router-view />
    <Transition name="fade">
      <VersionAlert @closeAlert="closeAlert()" class="absolute left-1/2 transform -translate-x-1/2 top-6 z-50" v-if="versionAlert.show && !versionAlert.deploy" />
    </Transition>
  </div>
  
</template>
<script>
import VersionAlert from '@/components/VersionAlert.vue';
export default {
  components: {
    VersionAlert
  },
  data() {
    return {
      versionAlert: {
        show: true,
        deploy: true
      }
    }
  },
  watch: {
    // Set meta tags
    $route(to) {
      this.setMeta(to.meta);
    },
  },
  methods: {
    closeAlert() {
      this.versionAlert.show = false
    },
    setMeta(meta) {
      document.title = meta.pageTitle;
      if(meta.title === undefined)
      {
        document.title = 'Jawgarden';
      }
    }
  },
  async created() {
    if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL') {
      this.$store.state.apiLink = "http://127.0.0.1:5001/jawgarden-4e611/europe-central2/api"
    } else if(process.env.VUE_APP_VERSION === 'PRODUCTION') {
      this.$store.state.apiLink = "https://europe-central2-jawgarden-4e611.cloudfunctions.net/api"
    }
    console.log(process.env.VUE_APP_VERSION);
  },
}
</script>
<style lang="scss" scoped>

body {
  overflow-x: hidden;
}

.fade-enter-active {
  transition: opacity 1s ease;
}
.fade-leave-active {
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

</style>

