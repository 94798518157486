import { createApp } from 'vue'
import FloatingVue from 'floating-vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/tailwind.css'
import '@/styles/tooltip.css'

createApp(App).use(store).use(router).use(FloatingVue).use(autoAnimatePlugin).mount('#app')
